import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import FileDownload from 'js-file-download';
import { momentToDatetimeString, datetimeToUTCString } from '@/common/helpers/date';
import { MONITORING } from '@/common/data/constants';
import moment from 'moment';

const JOBS_ENDPOINT = `${MONITORING}/reporting/jobs`;
const DIDNT_RUN_JOBS_ENDPOINT = `${JOBS_ENDPOINT}/didnt-run`;
const JOB_HISTORY_CHART_ENDPOINT = `${JOBS_ENDPOINT}/history-chart`;
const JOB_RANKING_ENDPOINT = `${JOBS_ENDPOINT}/ranking`;
const JOB_RANKING_FAILURES_ENDPOINT = `${JOB_RANKING_ENDPOINT}/failures`;
const JOB_RANKING_DURATION_ENDPOINT = `${JOB_RANKING_ENDPOINT}/duration`;
const JOB_RANKING_TRANSFERRED_ENDPOINT = `${JOB_RANKING_ENDPOINT}/transferred`;
const JOB_OVERVIEW_STATS_ENDPOINT = `${JOBS_ENDPOINT}/overview-stats`;
const SUPPORT_ENDPOINT = `${MONITORING}/backoffice/support`;
const REPOSITORIES_ENDPOINT = `${MONITORING}/reporting/repositories`;
const REPOSITORY_TREND_CHART_ENDPOINT = `${MONITORING}/reporting/repositories/trend`;
const REPOSITORIES_HISTORY_ENDPOINT = `${MONITORING}/reporting/repositories/history`;
const SPREADSHEET_REPORT_ENDPOINT = `${JOBS_ENDPOINT}/spreadsheet-report`;
const SPREADSHEET_REPORT_STATS_ENDPOINT = `${SPREADSHEET_REPORT_ENDPOINT}/stats`;
const LAST_SYNC_ENDPOINT = `${MONITORING}/sync/last-sync`;
const DISCOVERED_COMPUTERS_ENDPOINT = `${MONITORING}/reporting/discovery/computers`;
const DISCOVERED_COMPUTERS_IGNORE_ENDPOINT = `${MONITORING}/backoffice/computers/:_id/ignore`;
const DISCOVERED_COMPUTERS_NOTE_ENDPOINT = `${MONITORING}/backoffice/computers/:_id/note`;
const COMPUTER_STATUS_ENDPOINT = `${MONITORING}/reporting/discovery/computers/current`;
const EXPORT_ENDPOINT = `${MONITORING}/reporting/export`;
const LICENSING_ENDPOINT = `${MONITORING}/reporting/licensing`;
const LICENSING_COMPANIES_ENDPOINT = `${MONITORING}/reporting/licensing/companies`;
const SERVICE_PROVIDERS_ENDPOINT = 'reporting/service-providers';
const REPORT_SETTINGS_ENDPOINT = `${MONITORING}/reporting/reports/settings`;
const SINGLE_REPORT_SETTINGS_ENDPOINT = `${REPORT_SETTINGS_ENDPOINT}/:id`;
const ENABLED_REPORTS_ENDPOINT = `${MONITORING}/reporting/reports/enabled`;
const SEND_REPORTS_ENDPOINT = `${MONITORING}/reporting/reports/send`;

const SET_JOBS = 'reporting/SET_JOBS';
const SET_JOB = 'reporting/SET_JOB';
const SET_JOB_HISTORY = 'reporting/SET_JOB_HISTORY';
const SET_DIDNT_RUN_JOBS = 'reporting/SET_DIDNT_RUN_JOBS';
const SET_OVERVIEW_STATS = 'reporting/SET_OVERVIEW_STATS';
const SET_SUPPORT_RESPONSE = 'reporting/SET_SUPPORT_RESPONSE';
const SET_RANKING_FAILURES = 'reporting/SET_RANKING_FAILURES';
const SET_RANKING_DURATION = 'reporting/SET_RANKING_DURATION';
const SET_RANKING_TRANSFERRED = 'reporting/SET_RANKING_TRANSFERRED';
const SET_REPOSITORIES = 'reporting/SET_REPOSITORIES';
const SET_CALENDAR_REPORT = 'reporting/SET_CALENDAR_REPORT';
const SET_CALENDAR_REPORT_STATS = 'reporting/SET_CALENDAR_REPORT_STATS';
const SET_DASHBOARD_MANAGED_COMPANIES_FILTER = 'reporting/SET_DASHBOARD_MANAGED_COMPANIES_FILTER';
const SET_LAST_SYNC = 'reporting/SET_LAST_SYNC';
const SET_DISCOVERED_COMPUTERS = 'reporting/SET_DISCOVERED_COMPUTERS';
const SET_UPDATING_NOTE_TO_DISCOVERED_COMPUTER = 'reporting/SET_UPDATING_NOTE_TO_DISCOVERED_COMPUTER';
const SET_REPOSITORY_TREND_CHART = 'reporting/SET_REPOSITORY_TREND_CHART';
const SET_REPOSITORY_HISTORY = 'reporting/SET_REPOSITORY_HISTORY';
const SET_DAILY_CHECKS_SELECTED_ROWS = 'reporting/SET_DAILY_CHECKS_SELECTED_ROWS';
const SET_JOB_HISTORY_SELECTED_ROWS = 'reporting/SET_JOB_HISTORY_SELECTED_ROWS';
const SET_LICENSING = 'reporting/SET_LICENSING';
const SET_LICENSING_COMPANIES = 'reporting/SET_LICENSING_COMPANIES';
const SET_SERVICE_PROVIDERS = 'reporting/SET_SERVICE_PROVIDERS';
const SET_REPORT_SETTINGS = 'reporting/SET_REPORT_SETTINGS';
const SET_ENABLED_REPORTS = 'reporting/SET_ENABLED_REPORTS';
const SET_NEW_APP_FILTER = 'reporting/SET_NEW_APP_FILTER';
const SET_APP_FILTER = 'reporting/SET_APP_FILTER';
const SET_DISABLED_JOBS = 'reporting/SET_DISABLED_JOBS';
const SET_COMPUTER_STATUS = 'reporting/SET_COMPUTER_STATUS';
const SET_NEW_SOURCE_FILTER = 'reporting/SET_SOURCE_FILTER';
const CLEAR_SOURCE_FILTER = 'reporting/CLEAR_SOURCE_FILTER';
const SET_NEW_FILTER_TYPE = 'reporting/SET_FILTER_TYPE';
const CLEAR_FILTER_TYPE = 'reporting/CLEAR_FILTER_TYPE';
const SET_NEW_IGNORED_FILTER = 'reporting/SET_IGNORED_FILTER';
const CLEAR_IGNORED_FILTER = 'reporting/CLEAR_IGNORED_FILTER';
const SET_NEW_ANOMALY_FILTER = 'reporting/SET_ANOMALY_FILTER';
const CLEAR_ANOMALY_FILTER = 'reporting/CLEAR_ANOMALY_FILTER';
const SET_NEW_DATE_FILTER = 'reporting/SET_NEW_DATE_FILTER';
const CLEAR_DATE_FILTER = 'reporting/CLEAR_DATE_FILTER';
const SET_NEW_MANAGED_FILTER = 'reporting/SET_NEW_MANAGED_FILTER';
const CLEAR_MANAGED_FILTER = 'reporting/CLEAR_MANAGED_FILTER';
const SET_NEW_STATE_FILTER = 'reporting/SET_STATE_FILTER';
const CLEAR_STATE_FILTER = 'reporting/CLEAR_STATE_FILTER';

// initial state
const state = {
  jobs: {},
  didntRunJobs: {},
  jobHistory: {},
  support_response: {},
  rankingFailures: {},
  rankingDuration: {},
  rankingTransferred: {},
  overviewStats: {},
  repositories: {},
  calendarReport: {},
  calendarReportStats: {},
  dashboardManagedCompaniesFilter: null,
  lastSync: {},
  discoveredComputers: {},
  updatingNoteToDiscoveredComputers: false,
  repositoryTrendChart: {},
  repositoryHistory: {},
  jobsSelectedRows: [],
  jobHistorySelectedRows: [],
  licensing: [],
  licensingCompanies: [],
  serviceProviders: {},
  reportSettings: {},
  enabledReports: [],
  appFilters: getAppFilters(),
  disabledJobs: {},
  computerStatus: {},
  source: getSourceFilter(),
  filterType: getFilterType(),
  ignored: getIgnoredFilter(),
  anomaly: getAnomalyFilter(),
  dateFilter: getDateFilter(),
  managedFilter: getManagedFilter(),
  stateFilter: getStateFilter()
};

// actions
const actions = {
  GET_JOBS({ commit }, data) {
    let params = data;
    const { keepSelection } = params;
    delete params.keepSelection;
    if (params.from && params.to) {
      params.from = moment(params.from).startOf('day').toISOString();
      params.to = moment(params.to).endOf('day').toISOString();
    }
    if (params.note) params.note = { content: params.note };
    return new Promise((resolve, reject) => {
      axios
        .get(JOBS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          let processedData = [];
          data.data.forEach(
            (c, i) => (processedData[i] = { ...c, note: c.note ? c.note.content : '' })
          );
          const jobs = {
            count: data.count,
            data: processedData,
            query: data.query
          };
          commit(SET_JOBS, jobs);
          if (!keepSelection) commit(SET_DAILY_CHECKS_SELECTED_ROWS, []);
          resolve(jobs);
        })
        .catch(error => reject(error, 'Error while trying to get the jobs, try again later.'));
    });
  },
  GET_DIDNT_RUN_JOBS({ commit }, data) {
    let params = data;
    params.from = '"' + momentToDatetimeString(params.from) + '"';
    if (params.to) params.to = '"' + momentToDatetimeString(params.to) + '"';

    return new Promise((resolve, reject) => {
      axios
        .get(DIDNT_RUN_JOBS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          let processedData = [];
          data.data.forEach(
            (c, i) => (processedData[i] = { ...c, note: c.note ? c.note.content : '' })
          );
          const jobs = {
            count: data.count,
            data: processedData,
            query: data.query
          };
          commit(SET_DIDNT_RUN_JOBS, jobs);
          resolve(response.data);
        })
        .catch(() => reject('Error while trying to get the jobs, try again later.'));
    });
  },
  REMOVE_DIDNT_RUN_JOBS({ state, commit }, data) {
    const filteredJobs = state.didntRunJobs.data.filter(job => !data.includes(job.mongo_id));
    commit(SET_DIDNT_RUN_JOBS, {data: filteredJobs});
  },
  CLEAR_JOBS({ commit }) {
    commit(SET_JOBS, {});
  },
  CLEAR_DIDNT_RUN_JOBS({ commit }) {
    commit(SET_DIDNT_RUN_JOBS, {});
  },
  GET_JOB_HISTORY({ commit }, data) {
    let params = data;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(JOBS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          let processedData = [];
          data.data.forEach(
            (c, i) => (processedData[i] = { ...c, note: c.note ? c.note.content : '' })
          );
          const jobHistory = {
            count: data.count,
            data: processedData,
            query: data.query
          };
          commit(SET_JOB_HISTORY, jobHistory);
          resolve(jobHistory);
        })
        .catch(error =>
          reject(error, 'Error while trying to get the job history, try again later.')
        );
    });
  },
  CLEAR_JOB_HISTORY({ commit }) {
    commit(SET_JOB_HISTORY, {});
  },
  GET_JOB_HISTORY_CHART(context, data) {
    let params = data;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(JOB_HISTORY_CHART_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  ADD_JOB_TO_LIST({ commit, state }, job) {
    let jobs = JSON.parse(JSON.stringify(state.jobs));
    const elementIndex = jobs.data.findIndex(c => c._id === job._id);
    const jobToAdd = {
      ...job,
      note: job.note ? job.note.content : ''
    };
    if (elementIndex === -1) {
      jobs.data.unshift(jobToAdd);
      jobs.count++;
    } else {
      jobs.data[elementIndex] = jobToAdd;
    }
    commit(SET_JOBS, jobs);
  },
  GET_OVERVIEW_STATS({ commit }, data) {
    let params = data;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(JOB_OVERVIEW_STATS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_OVERVIEW_STATS, response.data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the stats, try again later.'));
    });
  },
  CLEAR_OVERVIEW_STATS({ commit }) {
    commit(SET_OVERVIEW_STATS, {});
  },
  POST_SUPPORT_EMAIL({ commit }, data) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      axios
        .post(SUPPORT_ENDPOINT, data, config)
        .then(response => {
          commit(SET_SUPPORT_RESPONSE, response.data);
          resolve(response.data);
        })
        .catch(() => reject());
    });
  },
  GET_RANKING_FAILURES({ commit }, data) {
    let params = data;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(JOB_RANKING_FAILURES_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_RANKING_FAILURES, response.data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the ranking, try again later.'));
    });
  },
  GET_RANKING_DURATION({ commit }, data) {
    let params = data;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(JOB_RANKING_DURATION_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_RANKING_DURATION, response.data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the ranking, try again later.'));
    });
  },
  GET_RANKING_TRANSFERRED({ commit }, data) {
    let params = data;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(JOB_RANKING_TRANSFERRED_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_RANKING_TRANSFERRED, response.data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the ranking, try again later.'));
    });
  },
  CLEAR_RANKINGS({ commit }) {
    commit(SET_RANKING_FAILURES, {});
    commit(SET_RANKING_DURATION, {});
    commit(SET_RANKING_TRANSFERRED, {});
  },
  GET_REPOSITORIES({ commit }, data) {
    let params = data;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(REPOSITORIES_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_REPOSITORIES, data);
          resolve();
        })
        .catch(error =>
          reject(error, 'Error while trying to get the repositories, try again later.')
        );
    });
  },
  CLEAR_REPOSITORIES({ commit }) {
    commit(SET_REPOSITORIES, {});
  },
  GET_CALENDAR_REPORT({ commit }, data) {
    let params = data;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(SPREADSHEET_REPORT_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_CALENDAR_REPORT, data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the report, try again later.'));
    });
  },
  CLEAR_CALENDAR_REPORT({ commit }) {
    commit(SET_CALENDAR_REPORT, {});
  },
  GET_CALENDAR_REPORT_STATS({ commit }, data) {
    let params = data;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(SPREADSHEET_REPORT_STATS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_CALENDAR_REPORT_STATS, data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the report, try again later.'));
    });
  },
  CLEAR_CALENDAR_REPORT_STATS({ commit }) {
    commit(SET_CALENDAR_REPORT_STATS, {});
  },
  SET_DASHBOARD_MANAGED_COMPANIES_FILTER({ commit }, data) {
    commit(SET_DASHBOARD_MANAGED_COMPANIES_FILTER, data);
  },
  CLEAR_DASHBOARD_MANAGED_COMPANIES_FILTER({ commit }) {
    commit(SET_DASHBOARD_MANAGED_COMPANIES_FILTER, null);
  },
  GET_LAST_SYNC({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(LAST_SYNC_ENDPOINT)
        .then(response => {
          commit(SET_LAST_SYNC, response.data);
          resolve();
        })
        .catch(error =>
          reject(error, 'Error while trying to get the last sync info, try again later.')
        );
    });
  },
  GET_DISCOVERED_COMPUTERS({ commit }, data) {
    let params = _.cloneDeep(data);
    if (params.to) params.to = '"' + momentToDatetimeString(params.from) + '"';
    delete params.from;
    return new Promise((resolve, reject) => {
      axios
        .get(DISCOVERED_COMPUTERS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_DISCOVERED_COMPUTERS, data);
          resolve();
        })
        .catch(error =>
          reject(error, 'Error while trying to get the discovered computers, try again later.')
        );
    });
  },
  CLEAR_DISCOVERED_COMPUTERS({ commit }) {
    commit(SET_DISCOVERED_COMPUTERS, {});
  },
  IGNORE_COMPUTER(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(DISCOVERED_COMPUTERS_IGNORE_ENDPOINT.replace(':_id', params._id))
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying to add the computer to the ignore, try again later.'));
    });
  },
  REMOVE_IGNORE_COMPUTER(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(DISCOVERED_COMPUTERS_IGNORE_ENDPOINT.replace(':_id', params._id))
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying to remove the computer to the ignore, try again later.'));
    });
  },
  ADD_NOTE_TO_DISCOVERED_COMPUTER({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(DISCOVERED_COMPUTERS_NOTE_ENDPOINT.replace(':_id', params._id), { note: params.note })
        .then(response => {
          commit(SET_UPDATING_NOTE_TO_DISCOVERED_COMPUTER, false);
          resolve(response.data);
        })
        .catch(() => reject('Error while trying to add the note to the computer, try again later.'));
    })
  },
  UPDATING_NOTE_TO_DISCOVERED_COMPUTER({ commit }, params) {
    commit(SET_UPDATING_NOTE_TO_DISCOVERED_COMPUTER, params);
  },
  GET_REPOSITORY_TREND_CHART({ commit }, data) {
    let params = data;
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(REPOSITORY_TREND_CHART_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_REPOSITORY_TREND_CHART, response.data);
          resolve();
        })
        .catch(error => reject(error));
    });
  },
  CLEAR_REPOSITORY_TREND_CHART({ commit }) {
    commit(SET_REPOSITORY_TREND_CHART, {});
  },
  GET_REPOSITORY_HISTORY({ commit }, data) {
    let params = data;
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    return new Promise((resolve, reject) => {
      axios
        .get(REPOSITORIES_HISTORY_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_REPOSITORY_HISTORY, data);
          resolve();
        })
        .catch(error =>
          reject(error, 'Error while trying to get the repository history, try again later.')
        );
    });
  },
  CLEAR_REPOSITORY_HISTORY({ commit }) {
    commit(SET_REPOSITORY_HISTORY, {});
  },
  EXPORT_DAILY_CHECKS_REPORT(context, query) {
    let params = query;
    params.from.startOf('day');
    params.to.endOf('day');
    params.from = '"' + datetimeToUTCString(params.from) + '"';
    params.to = '"' + datetimeToUTCString(params.to) + '"';
    if (params.note) params.note = { content: params.note };
    const data = {
      source: 'JOBS',
      format: {
        type: 'csv'
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .post(EXPORT_ENDPOINT, data, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          FileDownload(response.data, `DailyChecks-${Date.now()}.${data.format.type}`);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to export, try again later.'));
    });
  },
  ADD_DAILY_CHECKS_SELECTED_ROW({ commit, state }, { rowIndex, isSelectAll }) {
    if (isSelectAll)
      commit(
        SET_DAILY_CHECKS_SELECTED_ROWS,
        state.jobs.data.map((j, i) => i)
      );
    else commit(SET_DAILY_CHECKS_SELECTED_ROWS, [...state.jobsSelectedRows, rowIndex]);
  },
  REMOVE_DAILY_CHECKS_SELECTED_ROW({ commit, state }, selectedRowIndex) {
    const selectedRows = [...state.jobsSelectedRows];
    const indexToRemove = selectedRows.findIndex(r => r === selectedRowIndex);
    if (indexToRemove > -1) {
      selectedRows.splice(indexToRemove, 1);
    }
    commit(SET_DAILY_CHECKS_SELECTED_ROWS, selectedRows);
  },
  CLEAR_DAILY_CHECKS_SELECTED_ROWS({ commit }) {
    commit(SET_DAILY_CHECKS_SELECTED_ROWS, []);
  },
  ADD_JOB_HISTORY_SELECTED_ROWS({ commit, state }, { rowIndexes, isSelectAll }) {
    if (isSelectAll)
      commit(
        SET_JOB_HISTORY_SELECTED_ROWS,
        state.jobHistory.data.map((j, i) => i)
      );
    else commit(SET_JOB_HISTORY_SELECTED_ROWS, [...state.jobHistorySelectedRows, ...rowIndexes]);
  },
  REMOVE_JOB_HISTORY_SELECTED_ROW({ commit, state }, selectedRowIndex) {
    const selectedRows = [...state.jobHistorySelectedRows];
    const indexToRemove = selectedRows.findIndex(r => r === selectedRowIndex);
    if (indexToRemove > -1) {
      selectedRows.splice(indexToRemove, 1);
    }
    commit(SET_JOB_HISTORY_SELECTED_ROWS, selectedRows);
  },
  CLEAR_JOB_HISTORY_SELECTED_ROWS({ commit }) {
    commit(SET_JOB_HISTORY_SELECTED_ROWS, []);
  },
  ADD_JOB_TO_HISTORY_LIST({ commit, state }, job) {
    let jobHistory = _.cloneDeep(state.jobHistory);
    const elementIndex = jobHistory.data.findIndex(c => c._id === job._id);
    const jobToAdd = { ...job };
    if (elementIndex === -1) {
      jobHistory.data.unshift(jobToAdd);
      jobHistory.count++;
    } else {
      jobHistory.data[elementIndex] = jobToAdd;
    }
    commit(SET_JOB_HISTORY, jobHistory);
  },
  GET_LICENSING({ commit }, data) {
    let params = _.cloneDeep(data);
    if (params.from && params.to) {
      params.from.startOf('day');
      params.to.endOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    return new Promise((resolve, reject) => {
      axios
        .get(LICENSING_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_LICENSING, data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the licenses, try again later.'));
    });
  },
  CLEAR_LICENSING({ commit }) {
    commit(SET_LICENSING, []);
  },
  EXPORT_LICENSING({ commit }, data) {
    let params = _.cloneDeep(data);
    if (params.from && params.to) {
      params.from.startOf('day');
      params.to.endOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    const payload = {
      format: {
        type: 'csv'
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${LICENSING_ENDPOINT}/export`, payload, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          FileDownload(response.data, `Licensing-${Date.now()}.${payload.format.type}`);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to export, try again later.'));
    });
  },
  GET_SERVICE_PROVIDERS({ commit }, data) {
    let params = _.cloneDeep(data);
    return new Promise((resolve, reject) => {
      axios
        .get(SERVICE_PROVIDERS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_SERVICE_PROVIDERS, data);
          resolve();
        })
        .catch(error =>
          reject(error, 'Error while trying to get the service providers, try again later.')
        );
    });
  },
  CLEAR_SERVICE_PROVIDERS({ commit }) {
    commit(SET_SERVICE_PROVIDERS, {});
  },
  GET_LICENSING_COMPANIES({ commit }, data) {
    let params = _.cloneDeep(data);
    return new Promise((resolve, reject) => {
      axios
        .get(LICENSING_COMPANIES_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_LICENSING_COMPANIES, data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the licenses, try again later.'));
    });
  },
  CLEAR_LICENSING_COMPANIES({ commit }) {
    commit(SET_LICENSING_COMPANIES, []);
  },
  GET_REPORT_SETTINGS({ commit }, data) {
    let params = data;
    return new Promise((resolve, reject) => {
      axios
        .get(REPORT_SETTINGS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_REPORT_SETTINGS, data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the reports, try again later.'));
    });
  },
  CLEAR_REPORT_SETTINGS({ commit }) {
    commit(SET_REPORT_SETTINGS, {});
  },
  GET_ENABLED_REPORTS({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENABLED_REPORTS_ENDPOINT)
        .then(response => {
          const { data } = response;
          commit(SET_ENABLED_REPORTS, data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the reports, try again later.'));
    });
  },
  CLEAR_ENABLED_REPORTS({ commit }) {
    commit(SET_ENABLED_REPORTS, []);
  },
  ADD_REPORT_SETTINGS(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(REPORT_SETTINGS_ENDPOINT, data)
        .then(() => resolve())
        .catch(error => reject(error, 'Error while creating the report, try again later.'));
    });
  },
  EDIT_REPORT_SETTINGS(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(SINGLE_REPORT_SETTINGS_ENDPOINT.replace(':id', data._id), data)
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while editing the report. Try again later.');
        });
    });
  },
  DELETE_REPORT_SETTINGS(context, reportId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(SINGLE_REPORT_SETTINGS_ENDPOINT.replace(':id', reportId))
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while deleting the report. Try again later.');
        });
    });
  },
  SEND_REPORT(context, reportSettingId) {
    return new Promise((resolve, reject) => {
      axios
        .post(SEND_REPORTS_ENDPOINT, { reportSettingId })
        .then(() => resolve())
        .catch(error => reject(error, 'Error while sending the report, try again later.'));
    });
  },
  SET_APP_COMPANIES_FILTER({ commit }, data) {
    commit(SET_NEW_APP_FILTER, { filterName: 'companyIds', data });
  },
  SET_O365_APP_COMPANIES_FILTER({ commit }, data) {
    commit(SET_NEW_APP_FILTER, { filterName: 'o365companyIds', data });
  },
  CLEAR_APP_FILTERS({ commit }) {
    commit(SET_APP_FILTER, getDefaultAppFilters());
  },
  SET_APP_DAILY_CHECKS_STATUS_FILTER({ commit }, data) {
    commit(SET_NEW_APP_FILTER, {
      filterName: 'dailyChecksLastSessionState',
      data
    });
  },
  GET_DISABLED_JOBS({ commit }, data) {
    let params = data;
    if (params.from && params.to) {
      params.from.startOf('day');
      params.to.endOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    if (params.note) params.note = { content: params.note };
    params.jobScheduleState = 'Disabled';
    return new Promise((resolve, reject) => {
      axios
        .get(JOBS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          let processedData = [];
          data.data.forEach(
            (c, i) => (processedData[i] = { ...c, note: c.note ? c.note.content : '' })
          );
          const jobs = {
            count: data.count,
            data: processedData,
            query: data.query
          };
          commit(SET_DISABLED_JOBS, jobs);
          resolve(jobs);
        })
        .catch(error => reject(error, 'Error while trying to get the jobs, try again later.'));
    });
  },
  CLEAR_DISABLED_JOBS({ commit }) {
    commit(SET_DISABLED_JOBS, {});
  },
  GET_COMPUTER_STATUS({ commit }, data) {
    let params = _.cloneDeep(data);
    return new Promise((resolve, reject) => {
      axios
        .get(COMPUTER_STATUS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const { data } = response;
          commit(SET_COMPUTER_STATUS, data);
          resolve();
        })
        .catch(error =>
          reject(error, 'Error while trying to get the computer status, try again later.')
        );
    });
  },
  CLEAR_COMPUTER_STATUS({ commit }) {
    commit(SET_COMPUTER_STATUS, {});
  },
  SET_SOURCE_FILTER({ commit }, data) {
    commit(SET_NEW_SOURCE_FILTER, data);
  },
  CLEAR_SOURCE_FILTER({ commit }) {
    commit(CLEAR_SOURCE_FILTER, {});
  },
  SET_FILTER_TYPE({ commit }, data) {
    commit(SET_NEW_FILTER_TYPE, data);
  },
  CLEAR_FILTER_TYPE({ commit }) {
    commit(CLEAR_FILTER_TYPE);
  },
  SET_IGNORED_FILTER({ commit }, data) {
    commit(SET_NEW_IGNORED_FILTER, data);
  },
  CLEAR_IGNORED_FILTER({ commit }) {
    commit(CLEAR_IGNORED_FILTER, {});
  },
  SET_ANOMALY_FILTER({ commit }, data) {
    commit(SET_NEW_ANOMALY_FILTER, data);
  },
  CLEAR_ANOMALY_FILTER({ commit }) {
    commit(CLEAR_ANOMALY_FILTER, {});
  },
  SET_DATE_FILTER({ commit }, data) {
    commit(SET_NEW_DATE_FILTER, data);
  },
  CLEAR_DATE_FILTER({ commit }) {
    commit(CLEAR_DATE_FILTER);
  },
  SET_MANAGED_FILTER({ commit }, data) {
    commit(SET_NEW_MANAGED_FILTER, data);
  },
  CLEAR_MANAGED_FILTER({ commit }) {
    commit(CLEAR_MANAGED_FILTER);
  },
  SET_STATE_FILTER({ commit }, data) {
    commit(SET_NEW_STATE_FILTER, data);
  },
  CLEAR_STATE_FILTER({ commit }) {
    commit(CLEAR_STATE_FILTER, {});
  },
};

const getters = {
  jobs: state => state.jobs,
  didntRunJobs: state => state.didntRunJobs,
  support_response: state => state.support_response,
  jobHistory: state => state.jobHistory,
  rankingFailures: state => state.rankingFailures,
  rankingDuration: state => state.rankingDuration,
  rankingTransferred: state => state.rankingTransferred,
  overviewStats: state => state.overviewStats,
  repositories: state => state.repositories,
  calendarReport: state => state.calendarReport,
  calendarReportStats: state => state.calendarReportStats,
  dashboardManagedCompaniesFilter: state => state.dashboardManagedCompaniesFilter,
  lastSync: state => state.lastSync,
  discoveredComputers: state => state.discoveredComputers,
  repositoryTrendChart: state => state.repositoryTrendChart,
  repositoryHistory: state => state.repositoryHistory,
  jobsSelectedRows: state => state.jobsSelectedRows,
  jobHistorySelectedRows: state => state.jobHistorySelectedRows,
  licensing: state => state.licensing,
  licensingCompanies: state => state.licensingCompanies,
  serviceProviders: state => state.serviceProviders,
  reportSettings: state => state.reportSettings,
  enabledReports: state => state.enabledReports,
  appFilters: state => state.appFilters,
  getDisabledJobs: state => state.disabledJobs,
  getComputerStatus: state => state.computerStatus,
  getUpdatingNoteToDiscoveredComputers: state => state.updatingNoteToDiscoveredComputers,
  sourceFilter: state => state.source,
  filterType: state => state.filterType,
  ignoredFilter: state => state.ignored,
  anomalyFilter: state => state.anomaly,
  dateFilter: state => state.dateFilter,
  managedFilter: state => state.managedFilter,
  stateFilter: state => state.stateFilter
};

const mutations = {
  [SET_JOBS](state, data) {
    state.jobs = data;
  },
  [SET_JOB](state, { index, item }) {
    state.jobs.data[index] = item;
  },
  [SET_DIDNT_RUN_JOBS](state, data) {
    state.didntRunJobs = data;
  },
  [SET_JOB_HISTORY](state, data) {
    state.jobHistory = data;
  },
  [SET_OVERVIEW_STATS](state, data) {
    state.overviewStats = data;
  },
  [SET_SUPPORT_RESPONSE](state, data) {
    state.support_response = data.response;
  },
  [SET_RANKING_FAILURES](state, data) {
    state.rankingFailures = data;
  },
  [SET_RANKING_DURATION](state, data) {
    state.rankingDuration = data;
  },
  [SET_RANKING_TRANSFERRED](state, data) {
    state.rankingTransferred = data;
  },
  [SET_REPOSITORIES](state, data) {
    state.repositories = data;
  },
  [SET_CALENDAR_REPORT](state, data) {
    state.calendarReport = data;
  },
  [SET_CALENDAR_REPORT_STATS](state, data) {
    state.calendarReportStats = data;
  },
  [SET_DASHBOARD_MANAGED_COMPANIES_FILTER](state, data) {
    state.dashboardManagedCompaniesFilter = data;
  },
  [SET_LAST_SYNC](state, data) {
    state.lastSync = data;
  },
  [SET_DISCOVERED_COMPUTERS](state, data) {
    state.discoveredComputers = data;
  },
  [SET_UPDATING_NOTE_TO_DISCOVERED_COMPUTER](state, data) {
    state.updatingNoteToDiscoveredComputers = data;
  },
  [SET_REPOSITORY_TREND_CHART](state, data) {
    state.repositoryTrendChart = data;
  },
  [SET_REPOSITORY_HISTORY](state, data) {
    state.repositoryHistory = data;
  },
  [SET_DAILY_CHECKS_SELECTED_ROWS](state, data) {
    state.jobsSelectedRows = data;
  },
  [SET_JOB_HISTORY_SELECTED_ROWS](state, data) {
    state.jobHistorySelectedRows = data;
  },
  [SET_LICENSING](state, data) {
    if (data.length === 0) state.licensing = [];
    else if (state.licensing.length === 0) state.licensing.push(data);
    else {
      const idx = state.licensing.findIndex(l => l.serviceProvider.id === data.serviceProvider.id);
      // eslint-disable-next-line no-undef
      if (idx !== -1) Vue.set(state.licensing, idx, data);
      else state.licensing.push(data);
    }
  },
  [SET_LICENSING_COMPANIES](state, data) {
    if (data.length === 0) state.licensingCompanies = [];
    else if (state.licensingCompanies.length === 0) state.licensingCompanies.push(data);
    else {
      const idx = state.licensingCompanies.findIndex(
        l => l.serviceProviderId === data.serviceProviderId
      );
      // eslint-disable-next-line no-undef
      if (idx !== -1) Vue.set(state.licensingCompanies, idx, data);
      else state.licensingCompanies.push(data);
    }
  },
  [SET_SERVICE_PROVIDERS](state, data) {
    state.serviceProviders = data;
  },
  [SET_REPORT_SETTINGS](state, data) {
    state.reportSettings = data;
  },
  [SET_ENABLED_REPORTS](state, data) {
    state.enabledReports = data;
  },
  [SET_NEW_APP_FILTER](state, { data, filterName }) {
    if (Array.isArray(data) && data.includes('clear')) {
      data = [];
    }

    if(filterName === 'companyIds') {
      //remove from data the duplicates items for companyIds
      data = Array.from(new Set(data));
    }

    state.appFilters[filterName] = data;
    localStorage.setItem('appFilters', JSON.stringify(state.appFilters));
  },
  [SET_APP_FILTER](state, data) {
    state.appFilters = data;
    localStorage.setItem('appFilters', JSON.stringify(state.appFilters));
  },
  [SET_DISABLED_JOBS](state, data) {
    state.disabledJobs = data;
  },
  [SET_COMPUTER_STATUS](state, data) {
    state.computerStatus = data;
  },
  [SET_NEW_SOURCE_FILTER](state, data) {
    state.source = data;
    localStorage.setItem('sourceFilter', JSON.stringify(state.source));
  },
  [CLEAR_SOURCE_FILTER](state) {
    state.source = getDefaultSourceFilter();
    localStorage.removeItem('sourceFilter');
  },
  [SET_NEW_FILTER_TYPE](state, data) {
    state.filterType = data;
    localStorage.setItem('filterType', JSON.stringify(state.filterType));
  },
  [CLEAR_FILTER_TYPE](state) {
    state.filterType = getDefaultFilterType();
    localStorage.removeItem('filterType');
  },
  [SET_NEW_IGNORED_FILTER](state, data) {
    state.ignoredFilter = data;
    localStorage.setItem('ignoredFilter', JSON.stringify(state.ignoredFilter));
  },
  [CLEAR_IGNORED_FILTER](state) {
    state.ignoredFilter = getDefaultIgnoredFilter();
    localStorage.removeItem('ignoredFilter');
  },
  [SET_NEW_ANOMALY_FILTER](state, data) {
    state.anomalyFilter = data;
    localStorage.setItem('anomalyFilter', JSON.stringify(state.anomalyFilter));
  },
  [CLEAR_ANOMALY_FILTER](state) {
    state.anomalyFilter = getDefaultAnomalyFilter();
    localStorage.removeItem('anomalyFilter');
  },
  [SET_NEW_DATE_FILTER](state, data) {
    state.dateFilter = data;
    localStorage.setItem('dateFilter', JSON.stringify(state.dateFilter));
  },
  [CLEAR_DATE_FILTER](state) {
    state.dateFilter = getDefaultDateFilter();
    localStorage.removeItem('dateFilter');
  },
  [SET_NEW_MANAGED_FILTER](state, data) {
    state.managedFilter = data;
    localStorage.setItem('managedFilter', JSON.stringify(state.managedFilter));
  },
  [CLEAR_MANAGED_FILTER](state) {
    state.managedFilter = getDefaultManagedFilter();
    localStorage.removeItem('managedFilter');
  },
  [SET_NEW_STATE_FILTER](state, data) {
    state.stateFilter = data;
    localStorage.setItem('stateFilter', JSON.stringify(state.stateFilter));
  },
  [CLEAR_STATE_FILTER](state) {
    state.stateFilter = getDefaultStateFilter();
    localStorage.removeItem('stateFilter');
  },
};

function getAppFilters() {
  let appFilters = JSON.parse(localStorage.getItem('appFilters'));
  if (!appFilters || typeof appFilters !== 'object') return getDefaultAppFilters();
  else return appFilters;
}

function getDefaultAppFilters() {
  return { companyIds: [] };
}

function getSourceFilter() {
  let sourceFilter = JSON.parse(localStorage.getItem('sourceFilter'));
  if (sourceFilter && Array.isArray(sourceFilter)) {
    return sourceFilter
  }
  return getDefaultSourceFilter();
}

function getDefaultSourceFilter() {
  return [];
}

function getDefaultFilterType() {
  return [];
}

function getFilterType() {
  let filterType = JSON.parse(localStorage.getItem('filterType'));
  if (Array.isArray(filterType) && filterType.length > 0) {
    return filterType;
  }
  return getDefaultFilterType();
}

function getIgnoredFilter() {
  let ignoredFilter = JSON.parse(localStorage.getItem('ignoredFilter'));
  if (ignoredFilter === true || ignoredFilter === false) {
    return ignoredFilter
  }
  return getDefaultIgnoredFilter();
}

function getDefaultIgnoredFilter() {
  return false;
}

function getAnomalyFilter() {
  let anomalyFilter = JSON.parse(localStorage.getItem('anomalyFilter'));
  if (anomalyFilter === true || anomalyFilter === false) {
    return anomalyFilter
  }
  return getDefaultAnomalyFilter();
}

function getDefaultAnomalyFilter() {
  return true;
}

function getDateFilter() {
  let dateFilter = JSON.parse(localStorage.getItem('dateFilter'));
  if (!dateFilter || typeof dateFilter !== 'string') return getDefaultDateFilter();
  else return dateFilter;
}

function getDefaultDateFilter() {
  return "Last 2 Days";
}

function getManagedFilter() {
  let managedFilter = JSON.parse(localStorage.getItem('managedFilter'));
  if (managedFilter === true || managedFilter === false) {
    return managedFilter;
  }
  return getDefaultManagedFilter();
}

function getDefaultManagedFilter() {
  return null;
}

function getStateFilter() {
  let stateFilter = JSON.parse(localStorage.getItem('stateFilter'));
  if(stateFilter) {
    return stateFilter
  }
  return getDefaultStateFilter();
}

function getDefaultStateFilter() {
  return "pending";
}

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
