import axios from 'axios';
import qs from 'qs';

// Endpoints
const COMPANIES_ENDPOINT = 'companies';
const SINGLE_COMPANY_ENDPOINT = 'companies/:id';
const SINGLE_COMPANY_VEEAM_ID_ENDPOINT = 'companies/:veeamId';
const SINGLE_COMPANY_USERS_TO_NOTIFY_ENDPOINT = `${SINGLE_COMPANY_VEEAM_ID_ENDPOINT}/users-to-notify`;
const COMPANIES_REPORTING_ENDPOINT = 'reporting/companies';
const VAC_COMPANIES_ENDPOINT = `${COMPANIES_ENDPOINT}/vac`;

const SET_COMPANIES = 'companies/SET_COMPANIES';
const SET_USERS_TO_NOTIFY = 'companies/SET_USERS_TO_NOTIFY';
const SET_COMPANY = 'companies/SET_COMPANY';
const SET_VAC_COMPANIES = 'companies/SET_VAC_COMPANIES';
const SET_ALL_COMPANIES = 'companies/SET_ALL_COMPANIES';

// initial state
const state = {
  companies: {},
  usersToNotify: {},
  company: {},
  vacCompanies: {},
  allCompanies: {}
};

// actions
const actions = {
  ADD_COMPANY(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(COMPANIES_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while adding the company. Try again later.');
        });
    });
  },
  EDIT_COMPANY(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(SINGLE_COMPANY_ENDPOINT.replace(':id', data._id), data)
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while editing the company. Try again later.');
        });
    });
  },
  DELETE_COMPANY(context, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(SINGLE_COMPANY_ENDPOINT.replace(':id', companyId))
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while deleting the company. Try again later.');
        });
    });
  },
  GET_COMPANIES({ commit }, data) {
    let params = data;
    if (params.vacId || params.vcdId || params.zertoId) {
      params.applications = { vac: {}, vcd: {}, zerto: {} };
      if (params.vacId) {
        params.applications.vac.id = params.vacId;
        delete params.vacId;
      }
      if (params.vcdId) {
        params.applications.vcd.id = params.vcdId;
        delete params.vcdId;
      }
      if (params.zertoId) {
        params.applications.zerto.id = params.zertoId;
        delete params.zertoId;
      }
    }
    if (params.sort) {
      if (params.sort.includes('vacId')) {
        params.sort = params.sort.replace('vacId', 'applications.vac.id');
      }
    }
    if (params.users) {
      params.users = { username: params.users };
    }
    return new Promise((resolve, reject) => {
      axios
        .get(COMPANIES_REPORTING_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
        .then(response => {
          const { data } = response;
          let processedData = [];
          data.data.forEach(
            (c, i) =>
              (processedData[i] = {
                ...c,
                vacId: c.applications.vac && c.applications.vac.id ? c.applications.vac.id : '',
                vcdId: c.applications.vcd && c.applications.vcd.id ? c.applications.vcd.id : '',
                zertoId:
                  c.applications.zerto && c.applications.zerto.id ? c.applications.zerto.id : ''
              })
          );
          const companies = {
            count: data.count,
            data: processedData,
            query: data.query
          };
          commit(SET_COMPANIES, companies);
          resolve();
        })
        .catch(error => reject(error, 'GET_COMPANIES_ERROR_MESSAGE'));
    });
  },
  CLEAR_COMPANIES({ commit }) {
    commit(SET_COMPANIES, {});
  },
  ADD_COMPANY_TO_LIST({ commit, state }, company) {
    let companies = JSON.parse(JSON.stringify(state.companies));
    const elementIndex = companies.data.findIndex(c => c._id === company._id);
    const companyToAdd = {
      ...company,
      vacId: company.applications.vac.id ? company.applications.vac.id : '',
      vcdId: company.applications.vcd.id ? company.applications.vcd.id : '',
      // zertoId: company.applications.zerto.id ? company.applications.zerto.id : ''
    };
    if (elementIndex === -1) {
      companies.data.unshift(companyToAdd);
      companies.count++;
    } else {
      companies.data[elementIndex] = companyToAdd;
    }
    commit(SET_COMPANIES, companies);
  },
  DELETE_COMPANY_FROM_LIST({ commit, state }, companyId) {
    let companies = JSON.parse(JSON.stringify(state.companies));
    const elementIndex = companies.data.findIndex(c => c._id === companyId);
    if (elementIndex !== -1) {
      companies.data.splice(elementIndex, 1);
      companies.count--;
    }
    commit(SET_COMPANIES, companies);
  },
  GET_ALL_COMPANIES({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(COMPANIES_ENDPOINT, {
          params: data,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_ALL_COMPANIES, response.data);
          resolve();
        })
        .catch(error => reject(error, 'GET_ALL_COMPANIES'));
    });
  },
  GET_USERS_TO_NOTIFY({ commit }, veeamId) {
    return new Promise((resolve, reject) => {
      axios
        .get(SINGLE_COMPANY_USERS_TO_NOTIFY_ENDPOINT.replace(':veeamId', veeamId))
        .then(response => {
          commit(SET_USERS_TO_NOTIFY, response.data.users);
          resolve();
        })
        .catch(() => {
          reject('Error while getting the users to notify. Try again later.');
        });
    });
  },
  GET_COMPANY({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(SINGLE_COMPANY_ENDPOINT.replace(':id', companyId))
        .then(response => {
          commit(SET_COMPANY, response.data);
          resolve(response.data);
        })
        .catch(() => {
          reject('Error while getting the company. Try again later.');
        });
    });
  },
  GET_VAC_COMPANIES({ commit }, { name, id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(VAC_COMPANIES_ENDPOINT, { params: { name, id } })
        .then(response => {
          commit(SET_VAC_COMPANIES, response.data);
          resolve();
        })
        .catch(() => reject('GET_VAC_COMPANIES'));
    });
  },
  CLEAR_VAC_COMPANIES({ commit }) {
    commit(SET_VAC_COMPANIES, {});
  }
};

const getters = {
  getCompanies: state => state.companies,
  getUsersToNotify: state => state.usersToNotify,
  getCompany: state => state.company,
  getVacCompanies: state => state.vacCompanies,
  getAllCompanies: state => state.allCompanies
};

const mutations = {
  [SET_COMPANIES](state, data) {
    state.companies = data;
  },
  [SET_USERS_TO_NOTIFY](state, data) {
    state.usersToNotify = data;
  },
  [SET_COMPANY](state, data) {
    state.company = data;
  },
  [SET_VAC_COMPANIES](state, data) {
    state.vacCompanies = data;
  },
  [SET_ALL_COMPANIES](state, data) {
    state.allCompanies = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
